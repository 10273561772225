import { UserIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import { ChangeEventHandler } from "react";

import { DutyFreeDeclarationInfo, getBadgeSettingsFromOrderType } from "@web/common";
import {
  Agent,
  Badge,
  CircledImage,
  CloseHeader,
  DeliveryDateDetails,
  DeliveryMethod,
  DeliveryPort,
  Heading,
  Invoicing,
  Label,
  PONumber,
  Paragraph,
  StorageLabel,
  SummaryBoxUI,
  Textarea,
} from "@web/ui";
import { extractFromISODateString } from "@web/utils";

import { SupplierOrder, SupplierOrderService, ValidatedSupplierOrderForm } from "src/domain";
import VesselIcon from "src/icons/VesselIcon.svg";

type Props = {
  editedOrder: ValidatedSupplierOrderForm;
  originalOrder: SupplierOrder;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  note: string;
  handleNoteChange: ChangeEventHandler<HTMLTextAreaElement>;
  noteError?: string;
};

export const ConfirmOrder: React.FC<Props> = ({
  editedOrder,
  originalOrder,
  isDisabled,
  isLoading,
  note,
  handleNoteChange,
  onSubmit,
  onCancel,
  noteError,
}) => {
  const canShowOrderType = !SupplierOrderService.isDefaultOrderType(originalOrder);
  const isOrderNameEnabled = useFlag("order-name");

  return (
    <div className="flex flex-col bg-neutral_100 min-h-full">
      <CloseHeader
        onClose={onCancel}
        title={
          <div className="m-auto">
            <Label size="200">{originalOrder.customerName}</Label>{" "}
            <Label size="200" color="text-textIcon-blackSecondary">
              {originalOrder.orderId}
            </Label>
          </div>
        }
      />
      <div className="max-w-[1024px] mx-auto px-8 py-6 mb-10 flex w-full">
        <div className="flex-grow bg-neutral_200 pb-5 flex flex-col max-w-[550px] rounded-lg p-5">
          <Heading size="300" className="mb-4 flex items-center">
            Order Information
            {canShowOrderType && (
              <Badge
                {...getBadgeSettingsFromOrderType({
                  orderType: originalOrder.orderType,
                  text: isOrderNameEnabled ? originalOrder.catalogName : originalOrder.subject,
                })}
                size="s"
                className="ml-2 mr-auto"
              />
            )}
          </Heading>
          <div className="flex mb-5">
            <div className="flex flex-col w-1/2 pr-2">
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className=" uppercase mb-1"
              >
                Origin
              </Paragraph>
              <div className="flex">
                <CircledImage Icon={VesselIcon} size={6} hashKey={originalOrder.vessel.name} />
                <div className="flex flex-col pl-3 truncate">
                  <Label size="200" className="truncate">
                    {originalOrder.vessel.name}
                  </Label>
                  <Label size="200" color="text-textIcon-blackSecondary">
                    {originalOrder.vessel.imoNumber}
                  </Label>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className=" uppercase mb-1"
              >
                point of contact
              </Paragraph>
              <div className="flex">
                <CircledImage
                  Icon={() => <UserIcon width="16" color="white" />}
                  size={6}
                  hashKey={originalOrder.vessel.contactInformation?.name}
                />
                <div className="flex flex-col pl-3 truncate">
                  <Label size="200" className="truncate">
                    {originalOrder.vessel.contactInformation?.name}
                  </Label>
                  <Label size="200" color="text-textIcon-blackSecondary">
                    {originalOrder.vessel.contactInformation?.email}
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-3" />
          <div className="flex flex-col gap-3 py-3">
            <DeliveryPort
              portName={originalOrder.port.name}
              portCode={originalOrder.port.locationCode}
            />
            <DutyFreeDeclarationInfo dutyFreeDeclaration={originalOrder.dutyFreeDeclaration} />
            <DeliveryDateDetails
              date={extractFromISODateString(originalOrder.deliveryDate, "justDate")}
              hour={extractFromISODateString(originalOrder.deliveryDate, "justTime")}
              label="delivery date"
            />
            <Agent variant="expanded" agentInformation={originalOrder.agentInformation} />
            <div className="grid grid-cols-2">
              {!!originalOrder.invoiceAccount && (
                <Invoicing invoiceAccount={originalOrder.invoiceAccount} />
              )}
              {!!originalOrder.customerOrderId && (
                <PONumber poNumber={originalOrder.customerOrderId} />
              )}
              {!!originalOrder.storageLabel && <StorageLabel label={originalOrder.storageLabel} />}
              {!!originalOrder.deliveryDetails && (
                <DeliveryMethod deliveryMethod={originalOrder.deliveryDetails} />
              )}
            </div>
          </div>
          <div className="pt-3 flex-1">
            <Paragraph size="300" color="text-textIcon-blackSecondary" className=" pb-2 uppercase">
              Add a note
            </Paragraph>
            <Textarea
              data-testid="orderConfirmField-note"
              parentClassName="py-0"
              value={note}
              onChange={handleNoteChange}
              errorMessage={noteError}
            />
          </div>
        </div>
        <div className="ml-7 w-1/3">
          <SummaryBoxUI
            title="Price breakdown"
            onSubmit={onSubmit}
            nrLineItems={editedOrder.items.length}
            nrRfqItems={0}
            nrExtraItems={0}
            isLoading={isLoading}
            ctaLabel="Confirm Order"
            showCtaButton={true}
            vatPrice={SupplierOrderService.formatZeroAmountInOrderCurrency(editedOrder)}
            totalPrice={SupplierOrderService.formatTotalGrossAmount(editedOrder)}
            totalItemsPrice={SupplierOrderService.formatOrderItemsTotalAmount(
              editedOrder.items,
              editedOrder.totalGrossAmount.currencyCode
            )}
            additionalCosts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
              editedOrder.amountAdditionalCosts
            )}
            discounts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
              editedOrder.amountDiscounts
            )}
            submitDisabled={isDisabled}
            supplier={undefined}
          />
        </div>
      </div>
    </div>
  );
};
