import classnames from "classnames";
import _sortBy from "lodash/sortBy";
import { useState } from "react";

import { Heading, Label, Paragraph, RegularButton } from "@web/ui";
import { isDefined } from "@web/utils";

import {
  Environment,
  ManagedFeatureFlags,
  ManagedUnleashFeatureFlags,
  UnleashEnvironment,
} from "../../models";
import { convertManagedUnleashFeatureFlagsToManagedFeatureFlags } from "../../utils";

type FeatureFlagsManagerProps = {
  environment?: Environment;
  unleashEnvironment?: UnleashEnvironment;
  unleashPanelUrl?: string;
  unleashFlagsState: ManagedUnleashFeatureFlags;
  originalFlagsOverrideState: ManagedFeatureFlags;
  onSubmit: (flagsOverrideFormValues: ManagedFeatureFlags) => void;
  onCancel: () => void;
};

export const FeatureFlagsManager = ({
  environment,
  unleashEnvironment,
  unleashPanelUrl,
  unleashFlagsState,
  originalFlagsOverrideState,
  onSubmit,
  onCancel,
}: FeatureFlagsManagerProps) => {
  const [flagsOverrideForm, setFlagsOverrideForm] = useState<ManagedFeatureFlags>(
    originalFlagsOverrideState
  );

  // Sort by flags name
  const sortedUnleashFlagsStateCollection = _sortBy(Object.entries(unleashFlagsState), [0]);

  const handleFlagOverrideChange = (name: string, isEnabled: boolean) =>
    setFlagsOverrideForm((oldState) => {
      return {
        ...oldState,
        [name]: isEnabled,
      };
    });

  const handleSave = () => {
    onSubmit(flagsOverrideForm);
  };

  const handleCancel = () => {
    setFlagsOverrideForm(originalFlagsOverrideState);
    onCancel();
  };

  const handleResetState = () => {
    // This resets to the state that was loaded from Unleash without any overrides
    setFlagsOverrideForm(convertManagedUnleashFeatureFlagsToManagedFeatureFlags(unleashFlagsState));
  };

  const areAnyFlagsAvailable = sortedUnleashFlagsStateCollection.length > 0;

  return (
    <div className="fixed inset-0 bg-neutral_0 z-40 flex">
      <div className="m-auto max-h-screen">
        <div className="max-h-screen px-8 pt-4 pb-8 overflow-y-auto">
          <Heading size="300">Override Unleash feature flags in your browser</Heading>
          <Paragraph size="300" className="mt-2">
            All feature flags that are <strong>enabled</strong> for particular environment, are{" "}
            <strong>disabled</strong> by default.
            <br />
            You can enable (opt-in) a feature flag in your browser for testing purposes. This will
            not affect other users.
          </Paragraph>
          <Paragraph size="300" className="mt-2">
            <strong>
              Only flags that are enabled for the particular environment and properly configured
              with a strategy in Unleash, can be overridden.
            </strong>
            <br />
            Some flags may not be enabled or configured properly in Unleash - you will not be able
            to override (enable) them. They are marked with a{" "}
            <span className="text-dangerDefault">red</span> border.
            <br />
            In this case, please configure that Unleash flag first, or ask someone to do it for you.
          </Paragraph>
          <Paragraph size="300" className="mt-2">
            <strong>Backend-only flags</strong> (ones prefixed with `be.` prefix in Unleash) are not
            listed, as they <strong>cannot be overridden</strong> in the browser.
          </Paragraph>
          <Paragraph size="300" className="mt-2">
            <strong>
              Changing flags state here won&apos;t work while you are Offline (either via DevTools
              or manually via a toggle in VA).
            </strong>
            <br />
            In this case, go Online, change the flags state & go back to Offline.
          </Paragraph>
          <Paragraph size="300" className="mt-2">
            This list does not refresh automatically. If a flag is added/changed/removed in Unleash,
            you need to refresh the page.
          </Paragraph>
          {environment && unleashEnvironment && unleashPanelUrl && (
            <>
              <Paragraph size="300" className="mt-2">
                You are running in <strong className="uppercase">{environment}</strong> environment.
                <br />
                This translates to <strong className="uppercase">{unleashEnvironment}</strong>{" "}
                environment in Unleash.
              </Paragraph>
              <Paragraph size="300" className="mt-2">
                The Unleash flags for this environment are configured in this panel:
                <br />
                <a href={unleashPanelUrl} target="_blank" className="underline">
                  Unleash Panel - {unleashEnvironment} environment
                </a>
              </Paragraph>
            </>
          )}

          {areAnyFlagsAvailable ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
              <table className="w-full text-sm text-left text-textIcon-blackSecondary dark:text-text-whiteDisabled">
                <thead className="text-xs bg-neutral_100">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      <Label size="300">Name</Label>
                    </th>
                    <th scope="col" className="px-6 py-3 text-center whitespace-nowrap">
                      <Label size="300">
                        State in Unleash
                        <br />
                        (readonly)
                      </Label>
                    </th>
                    <th scope="col" className="px-6 py-3 text-center whitespace-nowrap">
                      <Label size="300">State in your browser</Label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUnleashFlagsStateCollection.map(
                    ([
                      flagName,
                      {
                        isEnabled: isFlagEnabledForEnvInUnleash,
                        isProperlyConfigured: isFlagProperlyConfiguredInUnleash,
                      },
                    ]) => {
                      let flagOverrideState: boolean;

                      if (isFlagProperlyConfiguredInUnleash) {
                        flagOverrideState = isDefined(flagsOverrideForm[flagName])
                          ? flagsOverrideForm[flagName] || false
                          : isFlagEnabledForEnvInUnleash;
                      } else {
                        flagOverrideState = isFlagEnabledForEnvInUnleash;
                      }

                      return (
                        <tr
                          key={flagName}
                          className="bg-neutral_0 border-b hover:bg-neutral_100 text-textIcon-blackPrimary"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">{flagName}</td>
                          <td className="w-4 p-4">
                            <div className="flex justify-center items-center">
                              <input
                                checked={isFlagEnabledForEnvInUnleash}
                                disabled
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td className="w-4 p-4">
                            <div className="flex justify-center items-center">
                              {/* Only allow to override flags that are enabled in Unleash */}
                              <div
                                className={classnames("inline-flex border-1", {
                                  "border-transparent": isFlagProperlyConfiguredInUnleash,
                                  "border-dangerDefault": !isFlagProperlyConfiguredInUnleash,
                                })}
                              >
                                <input
                                  checked={flagOverrideState}
                                  onChange={() => {
                                    handleFlagOverrideChange(flagName, !flagOverrideState);
                                  }}
                                  type="checkbox"
                                  disabled={!isFlagProperlyConfiguredInUnleash}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <Heading size="300" className="mt-6 text-center text-dangerDefault">
              No flags are available in Unleash, or there was a problem with loading data from
              Unleash.
            </Heading>
          )}

          <div className="mt-5 flex justify-between">
            <div className="flex">
              <RegularButton
                variant="secondary"
                size="large"
                label="Cancel"
                onClick={handleCancel}
              />
            </div>
            {areAnyFlagsAvailable && (
              <div className="flex gap-2 justify-end">
                <RegularButton
                  variant="secondary"
                  size="large"
                  label="Reset browser flags state"
                  onClick={handleResetState}
                />
                <RegularButton variant="primary" size="large" label="Save" onClick={handleSave} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
