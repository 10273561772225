import { Popover } from "@headlessui/react";
import classnames from "classnames";
import { Fragment, PropsWithChildren, useCallback } from "react";

type DropdownProps = {
  hAlignTo: DropdownHAlign;
  vAlignTo: DropdownVAlign;
  items: DropdownItem[];
  stopPropagation?: boolean;
  "data-testid"?: string;
};

export type DropdownItem = {
  key?: string;
  shouldKeepDropdownOpen?: boolean;
  renderComponent: ({ closeDropdown }: { closeDropdown: () => void }) => React.ReactElement;
};

type DropdownHAlign = "start" | "end";
type DropdownVAlign = "top" | "bottom";

const hAlignToPanelClassNames: Readonly<{ [key in DropdownHAlign]: string }> = {
  start: "left-0",
  end: "right-0",
};

const vAlignToPanelClassNames: Readonly<{ [key in DropdownVAlign]: string }> = {
  top: "bottom-[calc(100%+5px)]",
  bottom: "top-[calc(100%+5px)]",
};

export const Dropdown = ({
  children,
  hAlignTo,
  vAlignTo,
  items,
  stopPropagation = false,
  "data-testid": testId = "",
}: PropsWithChildren<DropdownProps>) => {
  const hAlignPanelClassNames = hAlignToPanelClassNames[hAlignTo];
  const vAlignPanelClassNames = vAlignToPanelClassNames[vAlignTo];

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
    },
    [stopPropagation]
  );

  // If `data-testid` is needed for button or items, add them to the
  // `children` and/or to the particular panel item components.
  return (
    <Popover
      className="relative"
      onClick={handleOnClick}
      {...(testId ? { "data-testid": testId } : {})}
    >
      <Popover.Button as={Fragment}>{children}</Popover.Button>
      <Popover.Panel
        className={classnames(
          "w-[250px] max-h-[600px] py-[10px]",
          "overflow-x-hidden overflow-y-auto z-30",
          "bg-neutral_0 rounded-[8px] shadow-elevation-400",
          "absolute",
          hAlignPanelClassNames,
          vAlignPanelClassNames
        )}
        {...(testId ? { "data-testid": `${testId}_items` } : {})}
      >
        {({ close }) => (
          <>
            {items.map(({ renderComponent, shouldKeepDropdownOpen, key }, i) => {
              const itemKey = key || i;
              const component = renderComponent({ closeDropdown: close });
              return shouldKeepDropdownOpen ? (
                <Fragment key={itemKey}>{component}</Fragment>
              ) : (
                <Popover.Button as={Fragment} key={itemKey}>
                  {component}
                </Popover.Button>
              );
            })}
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};
