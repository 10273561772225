import { PaperAirplaneIcon } from "@heroicons/react/solid";
import { ReactElement, ReactNode } from "react";

import { Heading, Label, Paragraph, RegularButton, RfqBadge, Tooltip } from "../../atoms";

export interface PriceModifierUi {
  name: string;
  amount: string;
}

interface Props {
  title?: string;
  ctaLabel?: string;
  totalPrice?: string;
  totalItemsPrice: string;
  vatPrice: string;
  poInput?: ReactNode;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  email?: string;
  nrLineItems: number;
  nrRfqItems: number;
  nrExtraItems: number;
  isLoading?: boolean;
  additionalCosts?: PriceModifierUi[];
  discounts?: PriceModifierUi[];
  showCtaButton?: boolean;
  validationErrorMessage?: string;
  renderAttentionInfo?: ({ className }: { className: string }) => ReactElement | null;
  orderName?: string;
  supplier:
    | {
        id: string;
        name: string;
      }
    | undefined;
}

export const SummaryBoxUI = ({
  title = "Summary",
  ctaLabel = "",
  poInput,
  onSubmit = () => undefined,
  submitDisabled = false,
  totalPrice,
  totalItemsPrice,
  vatPrice,
  email,
  nrLineItems,
  nrRfqItems,
  nrExtraItems,
  isLoading = false,
  additionalCosts = [],
  discounts = [],
  showCtaButton = false,
  validationErrorMessage,
  renderAttentionInfo,
  orderName,
  supplier,
}: Props) => {
  const hasItemsSection =
    nrLineItems > 0 || nrExtraItems > 0 || additionalCosts.length > 0 || discounts.length > 0;

  return (
    <div
      className="px-5 py-5 border rounded-lg shadow bg-neutral_0 flex flex-col"
      data-testid="summary-box"
    >
      <Heading size="300">{title}</Heading>
      <hr className="mt-5" />

      {!!orderName && (
        <div className="mt-5">
          <Label size="200" color="text-textIcon-blackSecondary">
            Order Name
          </Label>
          <Paragraph
            size="100"
            weight="heavy"
            className="mt-1"
            data-testid="orderNameRepresentation"
          >
            {orderName}
          </Paragraph>
          <hr className="mt-5" />
        </div>
      )}

      {hasItemsSection && (
        <>
          {(nrLineItems > 0 || nrExtraItems > 0) && (
            <>
              <div className="flex flex-col gap-3 mt-5">
                {nrLineItems > 0 && (
                  <div>
                    <Label size="200" color="text-textIcon-blackSecondary">
                      {/* TODO #11962: Make `supplier` field required and
                       * remove guard below since the supplier info is always
                       * available. */}
                      {supplier?.name ? `${supplier.name} - Catalog\u00A0Items` : "Catalog Items"}
                    </Label>
                    <div className="flex justify-between items-center">
                      <Paragraph size="200">
                        {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
                      </Paragraph>
                      <Paragraph size="200">{totalItemsPrice}</Paragraph>
                    </div>
                  </div>
                )}
                {nrExtraItems > 0 && (
                  <div>
                    <Label size="200" color="text-textIcon-blackSecondary">
                      Extra Items
                    </Label>
                    <div className="flex justify-between items-center">
                      <Paragraph size="200">
                        {nrExtraItems} {`Item${nrExtraItems > 1 ? "s" : ""}`}
                      </Paragraph>
                      <Paragraph size="200">TBD</Paragraph>
                    </div>
                  </div>
                )}

                {nrLineItems > 0 && nrExtraItems > 0 && (
                  <div>
                    <Label size="200">Total Items</Label>
                    <div className="flex justify-between items-center">
                      <Paragraph size="200">
                        {nrLineItems + nrExtraItems}{" "}
                        {`Item${nrLineItems + nrExtraItems > 1 ? "s" : ""}`}
                      </Paragraph>
                      <Paragraph size="200">{totalItemsPrice}</Paragraph>
                    </div>
                  </div>
                )}
              </div>
              <hr className="mt-5" />
            </>
          )}

          {(additionalCosts.length > 0 || discounts.length > 0) && (
            <>
              <div className="flex flex-col gap-2 mt-5">
                {additionalCosts.map((additionalCost, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <Label
                      size="200"
                      color="text-textIcon-blackSecondary"
                      className="block max-w-[60%]"
                    >
                      <span className="block truncate max-w-full">{additionalCost.name}</span>
                    </Label>
                    <Paragraph size="200">{additionalCost.amount}</Paragraph>
                  </div>
                ))}
                {discounts.map((discount, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <Label
                      size="200"
                      color="text-textIcon-blackSecondary"
                      className="block max-w-[60%]"
                    >
                      <span className="block truncate max-w-full">{discount.name}</span>
                    </Label>
                    <Paragraph size="200">{discount.amount}</Paragraph>
                  </div>
                ))}
              </div>
              <hr className="mt-5" />
            </>
          )}

          <div className="flex justify-between items-center mt-5">
            <Paragraph size="200">Sales Tax VAT 0%</Paragraph>
            <Paragraph size="200">{vatPrice}</Paragraph>
          </div>
          <hr className="mt-5" />

          {!!totalPrice && (
            <>
              <div
                className="flex justify-between items-center mt-5"
                data-testid="summaryBox-totalPrice"
              >
                <Paragraph size="100" weight="heavy">
                  Total Price
                </Paragraph>
                <Paragraph size="100" weight="heavy">
                  {totalPrice}
                </Paragraph>
              </div>
              <hr className="mt-5" />
            </>
          )}
        </>
      )}

      {nrRfqItems > 0 && (
        <div className="bg-neutral_100 px-4 py-3 border-neutral_300 rounded-md border-1 mt-5">
          <div>
            <Label size="200" className="text-textIcon-blackSecondary">
              Requested Items
            </Label>
          </div>
          <div className="pt-1 flex justify-between items-center">
            <Paragraph size="200" data-testid="rfqCount">
              {nrRfqItems} {`Item${nrRfqItems > 1 ? "s" : ""}`}
            </Paragraph>
            <Tooltip tooltipText="This item will need to be requested for a quote">
              <RfqBadge className="cursor-default" />
            </Tooltip>
          </div>
        </div>
      )}

      {renderAttentionInfo && renderAttentionInfo({ className: "mt-5" })}

      {poInput}

      {showCtaButton && (
        <RegularButton
          className="mt-5"
          variant="primary"
          size="large"
          label={ctaLabel}
          width="container"
          LeadingIcon={PaperAirplaneIcon}
          disabled={submitDisabled || isLoading}
          loading={isLoading}
          onClick={onSubmit}
          data-testid="summaryBoxCTA"
        />
      )}

      {validationErrorMessage && (
        <Paragraph
          data-testid="summaryBox_validationError"
          className="mt-4 mb-2"
          color="text-dangerDefault"
          size="300"
          weight="heavy"
        >
          {validationErrorMessage}
        </Paragraph>
      )}

      {!!email && (
        <div className="flex flex-col text-center mt-3">
          <Paragraph size="300" color="text-textIcon-blackSecondary">
            Your order will be sent via
          </Paragraph>
          <Paragraph
            size="300"
            color="text-textIcon-blackSecondary"
            data-testid="summaryBox_requesterEmail"
          >
            {email}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
