import classnames from "classnames";
import { ButtonHTMLAttributes } from "react";

export type AutocompleteItemProps<T> = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "type" | "className"
> & {
  item: T;
  onClick: (selectedItem: T) => void;
};

export function AutocompleteItem<T>({
  item,
  onClick,
  children,
  ...rest
}: AutocompleteItemProps<T>) {
  return (
    <button
      type="button"
      className={classnames(
        "flex justify-between items-center",
        "w-full px-[16px] py-[10px] gap-[8px]",
        "bg-transparent hover:bg-primaryBackground"
      )}
      onClick={() => onClick(item)}
      {...rest}
    >
      {children}
    </button>
  );
}
