import { Tooltip } from "../atoms/Tooltip";

export const renderLongStringWithOptionalTooltip = (
  value: string,
  lengthLimit: number,
  // Function that returns an abbreviated string when string's length exceeds the given limit
  abbreviationFn: (str: string, lengthLimit: number) => string
) => {
  if (value.length <= lengthLimit) {
    return value;
  }

  return (
    <Tooltip tooltipText={value} key={value} className="whitespace-nowrap inline-block">
      {abbreviationFn(value, lengthLimit)}
    </Tooltip>
  );
};
