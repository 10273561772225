import { useIsMutating } from "@tanstack/react-query";

export const BlockUI: React.FC = () => {
  const isMutating = useIsMutating();

  if (isMutating) {
    return <div className="fixed inset-0 h-full w-full z-40 overflow-hidden" />;
  }

  return <></>;
};
