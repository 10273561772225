import classnames from "classnames";
import _debounce from "lodash/debounce";
import {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  InputHTMLAttributes,
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

import { usePrevious } from "@web/utils";

import { AutocompleteItem } from "./AutocompleteItem";

type Props<T> = Omit<InputHTMLAttributes<HTMLInputElement>, "onSelect" | "onChange"> & {
  items: T[];
  searchItems: (items: T[], query: string) => T[];
  renderResult: (resultItem: T) => ReactElement;
  onSelect: (selectedItem: T) => void;
  onChange: (value: string) => void;
  hasError: boolean;
  "data-testid"?: string;
};

function AutocompleteWithRef<T>(
  {
    className,
    hasError,
    items,
    onSelect,
    onChange,
    searchItems,
    renderResult,
    disabled,
    value = "",
    "data-testid": testId = "",
    ...props
  }: Props<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const AutocompleteItemWrapper = AutocompleteItem<T>;

  const [matchingItems, setMatchingItems] = useState<T[]>([]);

  const prevValue = usePrevious(value);

  const [isFocused, setIsFocused] = useState(false);
  const prevIsFocused = usePrevious(isFocused);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setIsFocusedDebounced = useCallback(
    _debounce((isFocusedValue: boolean) => {
      setIsFocused(isFocusedValue);
    }, 50),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDebounced = useCallback(
    _debounce((query: string) => {
      // Show all items if query is empty
      const results = query ? searchItems(items, query) : items;
      setMatchingItems(results);
    }, 100),
    [items, searchItems]
  );

  useEffect(() => {
    if (isFocused && !prevIsFocused) {
      const query: string = Array.isArray(value) ? value[0] : `${value}`;
      searchDebounced(query);
    }
  }, [isFocused, items, prevIsFocused, prevValue, searchDebounced, searchItems, value]);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
      searchDebounced(event.target.value);
    },
    [onChange, searchDebounced]
  );

  const handleOnSelect = useCallback(
    (item: T) => {
      onSelect(item);
    },
    [onSelect]
  );

  const areResultsShown = isFocused && matchingItems.length > 0;

  return (
    <div
      className={classnames(className, "relative focus:outline-none")}
      {...(testId && { "data-testid": `${testId}_container` })}
    >
      <input
        {...props}
        value={value}
        onChange={handleOnChange}
        ref={ref}
        type="text"
        className={classnames(
          "block w-full h-6.5 rounded-md px-3 focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white focus:ring-primaryDefault text-sm leading-5 shadow-sm border",
          {
            "border-dangerDefault": hasError,
            "bg-neutral_200": disabled,
            "bg-neutral_0": !disabled,
            "text-textIcon-blackSecondary": disabled,
          }
        )}
        disabled={disabled}
        onFocus={() => {
          // Set `isFocused` value both directly and debounced to show the results
          // immediately and override any debounced calls that may want
          // to lose the focus
          setIsFocused(true);
          setIsFocusedDebounced(true);
        }}
        onBlur={() => {
          // Set `isFocused` value in a debounced manner to allow the focus to move to the results
          // list, without rerendering the component and losing focus altogether
          setIsFocusedDebounced(false);
        }}
        {...(testId && { "data-testid": `${testId}_input` })}
      />
      {areResultsShown && (
        <div
          className={classnames(
            "max-h-[600px] py-[10px]",
            "overflow-x-hidden overflow-y-auto z-30",
            "bg-neutral_0 rounded-[8px] shadow-elevation-400",
            "absolute",
            "top-[calc(100%+5px)] left-0 right-0"
          )}
          {...(testId && { "data-testid": `${testId}_results` })}
        >
          {matchingItems.map((item, i) => (
            <AutocompleteItemWrapper
              key={i}
              item={item}
              onClick={(item) => {
                handleOnSelect(item);
                // Set `isFocused` value both directly and debounced to hide the results
                // immediately and override any debounced calls that may want
                // to restore the focus
                setIsFocused(false);
                setIsFocusedDebounced(false);
              }}
              onFocus={() => {
                // Override losing focus when user clicks on an item in the
                // results list or navigates to it with keyboard
                setIsFocusedDebounced(true);
              }}
              onBlur={() => {
                // Make sure the focus is lost if user navigates from the search
                // results with their keyboard
                setIsFocusedDebounced(false);
              }}
              {...(testId && { "data-testid": `${testId}_result` })}
            >
              {renderResult(item)}
            </AutocompleteItemWrapper>
          ))}
        </div>
      )}
    </div>
  );
}

export const Autocomplete = forwardRef(AutocompleteWithRef) as <T>(
  props: Props<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof AutocompleteWithRef>;
